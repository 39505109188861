import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { io } from 'socket.io-client';
import awsExports from './aws-exports';
import Speedometer from './Speedometer';
import './App.css';

// Configure Amplify with awsExports
Amplify.configure(awsExports);

const App = ({ signOut, user }) => {
    const [sensorData, setSensorData] = useState({});
    const [selectedTag, setSelectedTag] = useState(null);

    useEffect(() => {
        const socket = io('https://api.dem-ag.co.uk:5000');

        socket.on('sensorData', (data) => {
            console.log('Received sensor data:', data);
            try {
                const parsedData = JSON.parse(data);

                setSensorData((prevData) => ({
                    ...prevData,
                    [parsedData.mac_address]: parsedData,
                }));
            } catch (error) {
                console.error('Failed to parse sensor data:', error);
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    // Calculate average temperature and movement
    const calculateAverages = () => {
        const allData = Object.values(sensorData);
        if (allData.length === 0) return { avgTemp: 0, avgMovement: 0 };

        const totalTemp = allData.reduce((acc, data) => acc + Number(data.temperature), 0);
        const totalMovement = allData.reduce((acc, data) => acc + (data.movement === "N/A" ? 0 : Number(data.movement)), 0);

        return {
            avgTemp: totalTemp / allData.length,
            avgMovement: totalMovement / allData.length,
        };
    };

    const { avgTemp, avgMovement } = calculateAverages();

    const handleTagClick = (tag) => {
        setSelectedTag(tag);
    };

    const handleDashboardClick = () => {
        setSelectedTag(null); // Reset selected tag to show average data
    };

    return (
        <div className="App">
            <header className="app-header">
                <h1>DEMag</h1>
                <button onClick={signOut} className="logout-button">Logout</button>
            </header>

            <button onClick={handleDashboardClick} className="dashboard-button">
                Dashboard
            </button>

            <main className="main-content">
                {selectedTag ? (
                    // Show data for the selected tag
                    <div className="sensorData">
                        <div className="data-container">
                            <Speedometer
                                value={sensorData[selectedTag].temperature}
                                maxValue={100}
                                label="Temperature (°C)"
                            />
                            <Speedometer
                                value={sensorData[selectedTag].movement === "N/A" ? 0 : Number(sensorData[selectedTag].movement)}
                                maxValue={500}
                                label="Movement"
                            />
                        </div>
                    </div>
                ) : (
                    // Show average data if no tag is selected
                    <div className="overview">
                        <div className="data-container"> {/* Added this div to keep average data side by side */}
                            <Speedometer value={avgTemp.toFixed(1)} maxValue={100} label="Average Temperature (°C)" />
                            <Speedometer value={avgMovement.toFixed(1)} maxValue={500} label="Average Movement" />
                        </div>
                    </div>
                )}
            </main>

            <aside className="sidebar">
                <h3>Sensor Tags</h3>
                <ul className="tag-list">
                    {Object.keys(sensorData).map((tag) => (
                        <li
                            key={tag}
                            onClick={() => handleTagClick(tag)}
                            className={selectedTag === tag ? 'selected' : ''}
                        >
                            {tag}
                        </li>
                    ))}
                </ul>
            </aside>
        </div>
    );
};

export default withAuthenticator(App, {
    hideSignUp: true,
});
