import React from 'react';
import GaugeChart from 'react-gauge-chart';

const Speedometer = ({ value, maxValue, label }) => {
    return (
        <div>
            <h3>{label}</h3>
            <GaugeChart 
                id="gauge-chart"
                nrOfLevels={30}            // Number of color segments
                colors={['#FF5F6D', '#FFC371']}  // Color gradient
                arcWidth={0.3}             // Width of the gauge arc
                percent={value / maxValue}  // Percent of max value (0-1)
                textColor="#fff"           // Color of the gauge text
                needleColor="#fff"         // Color of the needle
                animate={true}             // Animation when data changes
                needleBaseColor="#464A4F"
                formatTextValue={() => `${value}`} // Show actual value without percentage sign
                style={{ width: '350px', height: '200px' }} // Adjust the size here
            />
        </div>
    );
};

export default Speedometer;
